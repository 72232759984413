<template>
    <div>
        <el-dialog
            title="车位管理"
            :visible.sync="dialogVisible"
            append-to-body
            width="36%"
            center=""
            :before-close="handleClose">
           
            <div class="eqname">
                <div class="eqona">
                    <span>设备名称：</span>
                    <el-select v-model="name" size="small" placeholder="请选择">
                        <el-option
                        v-for="item in options"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id">
                        </el-option>
                    </el-select>
                </div>
                <div class="tws">
                    <span>摄像头1管理车位数：</span>
                    <el-select v-model="name" size="small" placeholder="请选择">
                        <el-option
                        v-for="item in options"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id">
                        </el-option>
                    </el-select>
                </div>
            </div>

             <div class="eqname">
                <div class="eqona">
                    <span>摄像编号：</span>
                    <el-select v-model="camera_number" size="small" placeholder="请选择">
                        <el-option
                        v-for="item in 5"
                        :key="item"
                        :label="item"
                        :value="item">
                        </el-option>
                    </el-select>
                </div>
                
            </div>
            
            <div class="twss">
                <span>车位序号：</span>
                <el-select v-model="parking_serial" size="small" placeholder="请选择">
                    <el-option
                    v-for="item in 3"
                    :key="item"
                    :label="item"
                    :value="item">
                    </el-option>
                </el-select>
                <p>（面对面右手边是1，从右往左1，2，3）</p>
            </div>

            <div class="buildcont">
                <div class="bcont">
                    <span>楼栋：</span>
                    <el-input size="small" v-model="building"></el-input>
                </div>
                <div class="bcont">
                    <span>楼层：</span>
                    <el-input size="small" v-model="floor"></el-input>
                </div>
                <div class="bcont">
                    <span>区域：</span>
                    <el-input size="small" v-model="area"></el-input>
                </div>
            </div>
           
            <div class="bhcont">
                <span>车位编号：</span>
                <el-input size="small" v-model="parking_number"></el-input>
                <el-button size="small" type="success">加一</el-button>
            </div>
           
            
     

            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="handshow">确 定</el-button>
            </span>
        </el-dialog>

    </div>
</template>

<script>
export default {
    props:[
        'options'
    ],
    data(){
        return{
            dialogVisible:false,
            name:'',
            camera_number:'',
            parking_serial:'',
            building:'',
            floor:'',
            area:'',
            parking_number:'',
            id:'',
            UserInfo:JSON.parse(window.localStorage.getItem('user')),
        }
    },
    methods:{
        handleClose(){
            this.dialogVisible= false
        },
        handshow(){
            let data ={
                method:'co.parking.manage.update',
                agent_id:this.UserInfo.agent_id,
                id:this.id?this.id:0,
                device_id:this.name,
                camera_number:this.camera_number,
                parking_serial:this.parking_serial,
                building:this.building,
                floor:this.floor,
                area:this.area,
                parking_number:this.parking_number
            }
            if(this.id){
                this.$serve(data).then(res=>{
                    if(res.data.code==0){
                        this.$message({
                            message: "修改成功!",
                            type: "success",
                        });
                        this.dialogVisible =false
                        this.$parent.getcarmList()
                    }else{
                        this.$message({
                            message: "修改失败!",
                            type: "erroe",
                        });
                    }
                })
            }else{
                this.$serve(data).then(res=>{
                    if(res.data.code==0){
                        this.$message({
                            message: "添加成功!",
                            type: "success",
                        });
                        this.dialogVisible =false
                        this.$parent.getcarmList()
                    }else{
                        this.$message({
                            message: "添加失败!",
                            type: "erroe",
                        });
                    }
                })
            }
        },
        // 获取单挑数据
        getCarone(id){
            this.id = id
            let data={
                method:'co.parking.manage.get',
                id:id
            }
            this.$serve(data).then(res=>{
                if(res.data.code==0){
                    let data = res.data.data
                    this.serial_number= data.serial_number
                    this.name=data.device_id
                    this.camera_number = data.camera_number
                    this.parking_serial=data.parking_serial
                    this.building = data.building,
                    this.floor= data.floor
                    this.area=data.area
                    this.parking_number=data.parking_number
                }
            })
        }
    }
}
</script>

<style  scoped>
.eqname{
    display: flex;
    margin-bottom: 10px;
}
.eqona >>>.el-select{
    width: 150px;
    margin-right: 30px;
}
.eqonaxlh >>>.el-input{
    width: 135px;
}
.eqonaxlh{
    margin-right: 30px;
}
.tws >>> .el-select{
    width: 100px;
}
.waicont{
    display: flex;
}
.waicont span{
    display: block;
    width:200px;
    line-height: 32px;
}
.pakbut{
    text-align: center;
    margin-top: 20px;
}


.twss{
    display: flex;
}
.twss >>>.el-select{
    width: 160px;
}
.twss p{
    color: red;
    line-height: 32px;
}
.buildcont{
    display: flex;
    justify-content: space-evenly;
    margin-top: 20px;
}
.bcont{
    /* margin-left: 10px; */
}
.bcont >>> .el-input{
    width: 100px;
}
.bhcont{
    display: flex;
    margin-top: 20px;
    line-height: 32px;
}
.bhcont >>> .el-input{
    width: 140px;
    margin-right: 20px;
}
</style>