<template>
    <div class="reporting">
        <div style="display: flex;">
          <Headbuttom></Headbuttom>
           <el-button type="primary" style="margin-left:20px" @click="onPlus" size="small">
             <i class="el-icon-plus" />新增
            </el-button>
          <div @click="handFromshow"><el-input
          size="small"
          class="dev_dowm"
          placeholder=""
          suffix-icon="el-icon-arrow-down"
          :readonly="true"
          >
        </el-input>
        </div>
       
        </div>

        <div class="attheadfrom" v-if="flag">
        <el-form
            :inline="true"
            size="small"
            :model="searchParams"
            class="demo-form-inline"
        >
            <el-form-item label="设备名称">
                <el-select v-model="searchParams.device_id" placeholder="请选择">
                    <el-option
                    v-for="item in options"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id">
                    </el-option>
                </el-select>
            </el-form-item>

            <el-form-item label="设备序列号">
                <el-input v-model="searchParams.serial_number"></el-input>
            </el-form-item>

            <el-form-item label="车位编号">
                <el-input v-model="searchParams.parking_number"></el-input>
            </el-form-item>

            <el-form-item>
                <el-button type="primary" @click="onSubmit">{{$t("devtable.cx")}}</el-button>
            </el-form-item>

        </el-form>
        </div>

        <Tables @handlerow="handlerow"
        @handlpages="handlpages"
        @handleSizeChange="handleSizeChange"
        :tableLabel="tableLabel"
        :tableData="tableData"
        :flags="flags"
        :operation="operation"
        :configs="configs"></Tables>

        <cardiolg ref="park" :options="options"></cardiolg>
    </div>
</template>
<script>
import Tables from '@/components/table'
import cardiolg from './commpoents/cardiolg'
export default {
     components:{
        cardiolg,
        Tables,
   
    },
    data(){
        return{
            flag:false,
            searchParams:{
                device_id:'',
                serial_number:'',
                parking_number:''
            },
            options: [],
            tableLabel:[
            
            {
              prop: "name",
              label: '设备名称',
              align: "center",
            },
            {
              prop: "serial_number",
              label: '设备序列号',
              align: "center",
            },
            {
              prop: "camera_number",
              label: '摄像编号',
              align: "center",
            },
            {
              prop: "parking_serial",
              label: '车位序号',
              align: "center",
            },
            
            {
              prop: "building",
              label: '楼',
              align: "center",
            },
            {
              prop: "floor",
              label: '层',
              align: "center",
            },
            {
              prop: "area",
              label: '区',
              align: "center",
            },
            {
              prop: "parking_number",
              label: '车位编号',
              align: "center",
            }
            ],
            tableData:[],
            operation:{
              width:150,
              data:[
                {
                  name: "修改",
                  type: "success",
                },
                {
                  name: "删除",
                  type: "success",
                },
                
                
              ]
            },
            // 分页数据
            configs: {
              page_index:1,
              pagesize: 10,
              total: 20,
              loading: false, // 加载状态
            },
            flags: {
              show: false, // 多选是否显示
              ishow: true, // 序号是否显示
            },
            UserInfo:JSON.parse(window.localStorage.getItem('user')),
        }
    },
    mounted(){
        this.hanequlist()
        this.getcarmList()
    },
    methods:{
        // 获取设备
        hanequlist(){
            let data={
                method:'co.device.dropdown.list',
                agent_id:this.UserInfo.agent_id
            }
            this.$serve(data).then(res=>{
                if(res.data.code==0){
                    this.options = res.data.data
                }
            })
        },
        // 获取列表

        getcarmList(v){
          let data = {
              method:'co.parking.manage.list',
              page_index:v?v:1,
              page_size:10,
              agent_id:this.UserInfo.agent_id,
              device_id:this.device_id,
              serial_number:this.serial_number,
              parking_number:this.parking_number
          }
          this.$serve(data).then(res=>{
            if(res.data.code==0){
              this.configs.total = res.data.count
              this.tableData = res.data.data
            }
          })
        },

        onPlus(){
            this.$refs.park.dialogVisible = true
        },
        handFromshow(){
            this.flag = !this.flag
        },
        onSubmit(){
          this.getcarmList()
        },
        handlerow(v,name){
           if(name=='修改'){
             this.$refs.park.dialogVisible = true
             this.$refs.park.getCarone(v.id)
           }else{
             this.$confirm('是否删除?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
              }).then(() => {
                let data={
                  method:'co.parking.manage.del',
                  id:v.id
                }
                this.$serve(data).then(res=>{
                  if(res.data.code==0){
                    this.$message({
                      type: 'success',
                      message: '删除成功!'
                    });
                  }
                })
              }).catch(() => {
                this.$message({
                  type: 'info',
                  message: '已取消删除'
                });          
              });
             
           }
        },
        handlpages(v){
          this.getcarmList(v)
        },
        handleSizeChange(){

        }
    }
}
</script>
<style scoped>
.reporting{
    padding: 20px;
    background-color: #fff;
}
.dev_dowm{
    margin-left: 30px;
}
.attheadfrom {
    padding: 20px;
    margin-top: 10px;
  }
</style>